import React, { useCallback, useState } from "react";
import Layout from "../Layout";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useEffect } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { Col, Container, Row } from "react-bootstrap";
import IntroBlock from "../components/IntroBlock";
import { Alert } from "@mui/material";
import SEO from "../components/SEO";
import Hero from "../components/Hero";

const ContactPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validate = (values) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.message) {
      errors.message = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      number: "",
      message: "",
    },
    validate,
    onSubmit: (values, resetForm) => {
      submit(values, resetForm);
    },
  });

  const clearForm = () => {
    formik.setValues({
      name: "",
      number: "",
      email: "",
      message: "",
    });
  };

  const submit = async (values, resetForm) => {
    if (!executeRecaptcha) {
      return;
    }

    setIsSubmitting(true);

    const token = await executeRecaptcha("test");

    // validate form
    if (!token) {
      return;
    }

    axios
      .post(
        "https://40m9tgm4th.execute-api.eu-west-2.amazonaws.com/prod/verify-captcha",
        {
          token,
        },
        {
          headers: {
            "X-Api-Key": "lixdKITSataLlEj47lddh70zLO3HUNS54NNyFD7L",
          },
        }
      )
      .then((res) => {
        if (res.data.score < 0.6) {
          return;
        }

        // const testData = {
        //   name: "Jack Minchin",
        //   email: "jackminchin@gmail.com",
        //   number: "+44 7805 310 420",
        //   message: "This is a test message.",
        // };
        const body = {
            ...values,
            score: res.data.score,
        }

        axios
          .post(
            "https://40m9tgm4th.execute-api.eu-west-2.amazonaws.com/prod/form-submission",
            values,
            {
              headers: {
                "X-Api-Key": "lixdKITSataLlEj47lddh70zLO3HUNS54NNyFD7L",
              },
            }
          )
          .then((res) => {
            setIsSubmitting(false);
            setIsSuccess(true);
            clearForm();
            setError(false);
            setTimeout(() => {
              setIsSuccess(false);
            }, 10000);
          })
          .catch((err) => {
            setIsSubmitting(false);
            setError(true);
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        setError(true);
      });
  };

  return (
    <Layout>
      <SEO title="Contact Us" />

      <Hero imageName="contactBackground">
        <div style={{ width: "50%" }}>
          <h1 className="whiteText servicesHeading">Contact Us</h1>
        </div>
      </Hero>

      <Container>
        <Row style={{ marginTop: 50 }}>
          <Col xs={12} md={6}>
            <h2>Call us</h2>
            <p>
              TBL can assist you in your climate change and regulatory
              compliance needs. We can help you plan for the future, avoid risks
              and enhance opportunities for your business.
            </p>
            <p>
              <b>+44 (0) 7855 394 281</b>
            </p>

            <h2>Email</h2>
            <p>
              <b>info@tblgroup.co.uk</b>
            </p>

            <h2>Our Information</h2>
            <p>Company Number: 8785721</p>
            <p>
              {" "}
              Registered address: Little Sutton, Llandyssil, Montgomery, Powys,
              SY15 6LF
            </p>
          </Col>

          <Col xs={12} md={6}>
            <form onSubmit={formik.handleSubmit}>
              <Container fluid>
                <Row style={{ marginBottom: 25 }}>
                  <Col>
                    <h3>Use the form below to get in touch</h3>
                    <span className="smallDividerLeft"></span>
                  </Col>
                </Row>
                <Row style={{ marginBottom: 25 }}>
                  <Col>
                    {isSuccess && (
                      <Alert severity="success">
                        Message sent! We'll be in touch soon.
                      </Alert>
                    )}
                    {error && (
                      <Alert severity="error">
                        Something went wrong! We're working on fixing it, but
                        please send you enquiry to info@tblgroup.co.uk.
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                  <Col xs={6}>
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      // variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      style={{ width: "100%" }}
                      helperText={
                        formik.errors.name ? formik.errors.name : null
                      }
                      color={formik.errors.name ? "warning" : "primary"}
                      required
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                  <Col xs={6}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      required
                      helperText={
                        formik.errors.email ? formik.errors.email : null
                      }
                      color={formik.errors.email ? "warning" : "primary"}
                      style={{ width: "100%" }}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                  <Col>
                    <TextField
                      id="number"
                      name="number"
                      label="Phone Number"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.number}
                      style={{ width: "100%" }}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      id="message"
                      name="message"
                      label="Message"
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      required
                      helperText={
                        formik.errors.message ? formik.errors.message : null
                      }
                      color={formik.errors.message ? "warning" : "primary"}
                      rows={6}
                      multiline
                      style={{ width: "100%" }}
                      onBlur={formik.handleBlur}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: 20,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      {isSubmitting && (
                        <CircularProgress size={30} style={{ margin: 5 }} />
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting || !formik.isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ContactPage;
